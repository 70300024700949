<template>
  <div class="detail-container">
    <div class="main" v-if="contractData">
      <div style="background: #fff; padding: 10px 20px 40px">
        <div class="content-box" v-html="contractData"></div>
      </div>
      <div class="button-box">
        <div v-if="!waitTime && getIsShowBtn" class="confirm-btn" @click="confirmSign">签署</div>
        <div v-if="!waitTime && !getIsShowBtn" class="confirm-btn backc">对方未签署</div>
        <div v-if="waitTime" class="confirm-btn" style="background: #aaa; cursor: not-allowed">请仔细阅读合同 ({{ waitTime }}s)</div>
      </div>
    </div>
    <CFCATool id="CFCAId" ref="CFCARef" :signText="signText" @getSignInfo="getSignInfo" @getCertInfo="certInfo = $event" />
    <!-- 选择银行账号的弹窗 -->
    <el-dialog
      v-if="$route.query.customer == 'sellder'"
      class="container-dialog"
      title="提示"
      :center="true"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div>
        <el-form :rules="accountRules" :model="dialogAccountForm" ref="dialogAccount">
          <el-row>
            <el-col :offset="2">
              <el-form-item label="账号" prop="accountValue">
                <div>
                  <el-select v-model="dialogAccountForm.accountValue" class="dialog-select" placeholder="请选择">
                    <el-option class="dialog-select-dropdown" v-for="item in cities" :key="item.value" :label="item.accountNo" :value="item.id">
                      <span>开户银行：{{ item.bankName }}</span>
                      <br />
                      <span>银行账号：{{ item.accountNo }}</span>
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitAccount">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getContranctDetail, signContranct, getContractHash, collectPayInfoList, updateCollectPayId} from '../../api';
import Title from '../../component/Title';
import CFCATool from '@/components/CFCATool';
export default {
  components: {
    Title,
    CFCATool
  },
  props: {
    info: {
      type: Object
    }
  },
  data() {
    return {
      dialogAccountForm: {
        accountValue: ''
      },
      accountRules: {
        accountValue: [{required: true, message: '请选择账号', trigger: 'change'}]
      },
      cities: [],
      dialogVisible: false,
      contractData: null,
      signText: '',
      signInfo: '',
      certInfo: '',
      customer: this.$route.query.customer,
      waitTime: 15
    };
  },
  created() {
    this.getContranctDetail();
    this.getCollectPayInfoList();
  },
  computed: {
    getIsShowBtn() {
      if (this.customer == 'sellder') {
        if (this.info.orderInfo.orderStatus == 'DQS') return true;
      } else {
        if (this.info.orderInfo.orderStatus == 'DQS' && this.info.orderInfo.contractStatus == '1') return true;
      }
      return false;
    }
  },
  // 生命周期 - 卸载前
  beforeDestroy() {
    localStorage.removeItem('certInfo');
  },
  mounted() {
    this.computedWaitTime();
  },
  methods: {
    submitAccount() {
      this.$refs.dialogAccount.validate(async valid => {
        if (valid) {
          let res = await updateCollectPayId({id: this.$route.query.tciId, collectPayId: this.dialogAccountForm.accountValue});
          if (res.errno === 0) {
            this.dialogVisible = false;
          }
        }
      });
    },
    async getCollectPayInfoList() {
      let res = await collectPayInfoList();
      if (res.errno === 0) {
        this.cities = res.data;
      }
    },
    getContranctDetail() {
      getContranctDetail({
        tciId: this.$route.query.tciId
      }).then(res => {
        if (res.errno === 0) {
          this.contractData = res.data || {};
        }
      });
    },
    confirmSign() {
      if (!this.dialogAccountForm.accountValue && this.$route.query.customer === 'sellder') {
        this.dialogVisible = true;
        return;
      }
      // 未检测证书信息则不执行
      //   if (!this.$refs.CFCARef.selectCert()) return;

      if (this.$refs.CFCARef.checkIsIE()) {
        // 获取证书序列码
        if (!this.$refs.CFCARef.getCertInfo()) return;
        // 检测是否读取证书序列码
        if (!this.certInfo) return this.$message.info('未检测到证书信息');
      } else {
        this.$refs.CFCARef.getCertInfo();
        if (!this.certInfo) return;
        //  this.$message.info('未检测到证书信息')
      }
      localStorage.setItem('certInfo', this.certInfo);
      getContractHash({
        tciId: this.$route.query.tciId,
        id: this.$route.query.id,
        orderNum: this.$route.query.orderNum,
        clientName: this.$route.query.buy_client_name,
        chanel: 'lpp'
      }).then(res => {
        if (res.errno === 0) {
          this.signText = res.data.fileHash;
        }
      });
    },

    signContranct() {
      signContranct({
        id: this.$route.query.id,
        orderNum: this.$route.query.orderNum,
        signHash: this.signInfo
      }).then(res => {
        if (res.errno === 0) {
          this.$bus.$emit('updateDetails');
        }
      });
    },
    getSignInfo(val) {
      // 检测是否读取证书hash值
      if (!val) return this.$message.info('未检测到签名信息');
      this.signInfo = val;
      this.signContranct();
    },
    computedWaitTime() {
      let t = setInterval(() => {
        this.waitTime--;
        if (this.waitTime === 0) {
          clearInterval(t);
        }
      }, 1000);
    }
  }
};
</script>

<style lang="less">
.container-dialog .el-form-item__error {
  margin-left: 50px;
}
.dialog-select {
  width: 400px;
}
.dialog-select-dropdown {
  height: auto;
}
.container-dialog .el-select-dropdown__item {
  height: auto;
  background: red;
}
.el-select-dropdown /deep/ .el-select-dropdown__item {
  height: auto;
}
body /deep/ .el-dialog .el-dialog--center .container-dialog ::v-deep .el-form-item__error {
  margin-left: 50px;
}

.detail-container {
  background: #fff;

  .main {
    .content-box {
      padding: 0 16px;
      box-sizing: border-box;
      // max-height: 600px;
      // overflow: auto;
      // border: 1px solid #aaa;
    }
    .button-box {
      text-align: center;

      .confirm-btn {
        display: inline-block;
        margin-right: 50px;
        padding: 0 30px;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
        // height: 2.5em;
        line-height: 2.5em;
        background-image: linear-gradient(rgb(12, 165, 255), rgb(6, 121, 239));
        cursor: pointer;

        // &.no-read {
        //     background-image: linear-gradient(to right, #554f51, #5e5657);
        //     cursor: not-allowed;
        // }
      }
      .backc {
        cursor: not-allowed;
        background-image: none;
        background-color: #bbb;
      }
    }
  }
}
</style>
