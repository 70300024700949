var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-container" },
    [
      _vm.contractData
        ? _c("div", { staticClass: "main" }, [
            _c(
              "div",
              {
                staticStyle: { background: "#fff", padding: "10px 20px 40px" },
              },
              [
                _c("div", {
                  staticClass: "content-box",
                  domProps: { innerHTML: _vm._s(_vm.contractData) },
                }),
              ]
            ),
            _c("div", { staticClass: "button-box" }, [
              !_vm.waitTime && _vm.getIsShowBtn
                ? _c(
                    "div",
                    {
                      staticClass: "confirm-btn",
                      on: { click: _vm.confirmSign },
                    },
                    [_vm._v("签署")]
                  )
                : _vm._e(),
              !_vm.waitTime && !_vm.getIsShowBtn
                ? _c("div", { staticClass: "confirm-btn backc" }, [
                    _vm._v("对方未签署"),
                  ])
                : _vm._e(),
              _vm.waitTime
                ? _c(
                    "div",
                    {
                      staticClass: "confirm-btn",
                      staticStyle: {
                        background: "#aaa",
                        cursor: "not-allowed",
                      },
                    },
                    [_vm._v("请仔细阅读合同 (" + _vm._s(_vm.waitTime) + "s)")]
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _c("CFCATool", {
        ref: "CFCARef",
        attrs: { id: "CFCAId", signText: _vm.signText },
        on: {
          getSignInfo: _vm.getSignInfo,
          getCertInfo: function ($event) {
            _vm.certInfo = $event
          },
        },
      }),
      _vm.$route.query.customer == "sellder"
        ? _c(
            "el-dialog",
            {
              staticClass: "container-dialog",
              attrs: {
                title: "提示",
                center: true,
                "show-close": false,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.dialogVisible,
                width: "30%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "dialogAccount",
                      attrs: {
                        rules: _vm.accountRules,
                        model: _vm.dialogAccountForm,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { offset: 2 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "账号",
                                    prop: "accountValue",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "dialog-select",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.dialogAccountForm
                                                .accountValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dialogAccountForm,
                                                "accountValue",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "dialogAccountForm.accountValue",
                                          },
                                        },
                                        _vm._l(_vm.cities, function (item) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: item.value,
                                              staticClass:
                                                "dialog-select-dropdown",
                                              attrs: {
                                                label: item.accountNo,
                                                value: item.id,
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "开户银行：" +
                                                    _vm._s(item.bankName)
                                                ),
                                              ]),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "银行账号：" +
                                                    _vm._s(item.accountNo)
                                                ),
                                              ]),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitAccount },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }